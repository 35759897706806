import { user } from './user';
import { vehicle } from './vehicle';
import { order } from './order';
import { supervise } from './supervise';
import { bhtrans } from './bhtrans';

export default {
    user,
    vehicle,
    order,
    supervise,
    bhtrans
};
