import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import Loadable from 'react-loadable';
import './App.scss';
import PrivateRoute from './hoc/PrivateRoute';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = Loadable({
  loader: () => import('./containers/DefaultLayout'),
  loading
});

const LayoutCidades = Loadable({
  loader: () => import('./containers/LayoutCidades'),
  loading
});

// Pages
const Login = Loadable({
  loader: () => import('./views/Pages/Login'),
  loading
});

const Page404 = Loadable({
  loader: () => import('./views/Pages/Page404'),
  loading
});

const Page500 = Loadable({
  loader: () => import('./views/Pages/Page500'),
  loading
});

class App extends Component {

  render() {
    return (
      <HashRouter>
          <Switch>
            <Route exact path="/login" name="Login Page" component={Login} />
            <Route exact path="/404" name="Page 404" Component={Page404} />
            <Route exact path="/500" name="Page 500" Component={Page500} /> 
{/*             <PrivateRoute path="/igarape" name="Igarape" component={LayoutCidades}/>   */}  
            <PrivateRoute path="/" name=""  component={DefaultLayout}/>
          </Switch>
      </HashRouter>
    );
  }
}

export default App;
