
import { ToastContainer, toast } from 'react-toastify';
import { cancelOrderPdv, authorizeChargeback, refuseChargeback, processPayment } from '../repositories'
import 'react-toastify/dist/ReactToastify.css';

export const order = {
    state: {},
    reducers: {
        clearStore() {
            return {};
        },
    },
    effects: dispatch => ({
        async processPaymentAsync(payload) {
            try {
                const { paymentId } = payload;
                const payment = await processPayment(paymentId)
                if(payment.status === 200){
                    toast.success('Pedido foi processado com sucesso!', {
                        position: toast.POSITION.TOP_RIGHT
                    })                
                }

                return payment;
            } catch (e) {
                throw e;
            }

        },
        async refuseChargebackAsync(payload) {
            try {
                const { orderId } = payload;
                const order = await refuseChargeback(orderId)
                if(order.status === 200){
                    toast.success('Estorno foi recusado! Os créditos retornaram para o saldo do condutor.', {
                        position: toast.POSITION.TOP_RIGHT
                    })                
                }

                return order;
            } catch (e) {
                throw e;
            }

        },
        async authorizeChargebackAsync(payload) {
            try {
                const { orderId } = payload;
                const order = await authorizeChargeback(orderId)
                if(order.status === 200){
                    toast.success('Estorno foi autorizado com sucesso!', {
                        position: toast.POSITION.TOP_RIGHT
                    })                
                }

                return order;
            } catch (e) {
                throw e;
            }

        },
        async cancelOrderPdvAsync(payload) {
            try {
                const { sellerId, nsu, date } = payload;
                const order = await cancelOrderPdv(sellerId, nsu, date)
                if(order.status === 200){
                    toast.success('Venda cancelada com sucesso!', {
                        position: toast.POSITION.TOP_RIGHT
                    })                
                }

                return order;
            } catch (e) {
                throw e;
            }

        },
        clearStores() {
            return new Promise(resolve => {
                dispatch.order.clearStore();
                resolve();
            });
        },
    }),
};
