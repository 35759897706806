import { toast } from "react-toastify";
import {
  getFiscalSupervisePhotos,
  signInFiscal,
  getFiscalSupervises,
  approveFiscalSupervise,
  refuseFiscalSupervise,
  getFiscalSupervisesDetails,
} from "../repositories";

export const supervise = {
  state: {},
  reducers: {
    setLoading(state, payload) {
      return {
        ...state,
        loading: payload,
      };
    },
    setLoadingApprove(state, payload) {
      return {
        ...state,
        loadingApprove: payload,
      };
    },
    setLoadingRefuse(state, payload) {
      return {
        ...state,
        loadingRefuse: payload,
      };
    },
    clearStore() {
      return {};
    },
  },
  effects: (dispatch) => ({
    async signInFiscalAsync(payload) {
      try {
        dispatch.supervise.setLoading(true);
        const response = await signInFiscal(payload);
        const data = response.data ? response.data : null;
        dispatch.user.signIn({ ...data, type: "FISCAL" });
        return data;
      } catch (e) {
        throw e;
      } finally {
        dispatch.supervise.setLoading(false);
      }
    },
    async getFiscalSupervisePhotosAsync(payload) {
      try {
        dispatch.supervise.setLoading(true);
        const response = await getFiscalSupervisePhotos(payload);
        const data = response.data ? response.data : null;
        return data;
      } catch (e) {
        throw e;
      } finally {
        dispatch.supervise.setLoading(false);
      }
    },
    async getFiscalSupervisesAsync(payload) {
      try {
        dispatch.supervise.setLoading(true);
        const response = await getFiscalSupervises(payload);
        const data = response.data ? response.data : null;
        return data;
      } catch (e) {
        throw e;
      } finally {
        dispatch.supervise.setLoading(false);
      }
    },
    async getFiscalSupervisesDetailsAsync(id) {
      try {
        dispatch.supervise.setLoading(true);
        const response = await getFiscalSupervisesDetails(id);
        const data = response.data ? response.data : null;
        return data;
      } catch (e) {
        throw e;
      } finally {
        dispatch.supervise.setLoading(false);
      }
    },
    async approveFiscalSupervisesAsync(id) {
      try {
        dispatch.supervise.setLoadingApprove(true);
        const response = await approveFiscalSupervise(id);
        if (response.status === 200) {
          toast.success("Fiscalização aprovada com sucesso!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } catch (e) {
        throw e;
      } finally {
        dispatch.supervise.setLoadingApprove(false);
      }
    },
    async refuseFiscalSupervisesAsync(id) {
      try {
        dispatch.supervise.setLoadingRefuse(true);
        const response = await refuseFiscalSupervise(id);
        if (response.status === 200) {
          toast.success("Fiscalização rejeitada com sucesso!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } catch (e) {
        throw e;
      } finally {
        dispatch.supervise.setLoadingRefuse(false);
      }
    },
    clearStores() {
      return new Promise((resolve) => {
        dispatch.fiscalSupervisePhotos.clearStore();
        resolve();
      });
    },
  }),
};
