import {
    getBalanceBHTransApp,
    getBalanceBHTransPdv
} from '../repositories';

export const bhtrans = {
    state: {},
    reducers: {
        clearStore() {
            return {};
        },
    },
    effects: dispatch => ({
        async getBalanceBHTransAppAsync(payload) {
            try {
                const response = await getBalanceBHTransApp(payload);
                const data = response.data ? response.data : null
                return data;
            } catch (e) {
                throw e;
            }
        },
        async getBalanceBHTransPdvAsync(payload) {
            try {
                const response = await getBalanceBHTransPdv(payload);
                const data = response.data ? response.data : null
                return data;
            } catch (e) {
                throw e;
            }
        },
        clearStores() {
            return new Promise(resolve => {
                dispatch.bhtrans.clearStore();
                resolve();
            });
        },
    }),
};
