import axios from "axios";
import { toast } from "react-toastify";
import {
  CADASTRA,
  POS,
  TRANSACIONA,
  MINUTE,
  ARQUIVO,
  BHTRANS,
  BHTRASPFDV,
  FISCALIZA,
  AUTENTICA,
} from "../utils/constants";

const instanceLoginCadastro = (API) => {
  const instanceLoginCadastro = axios.create({
    baseURL: API,
    timeout: MINUTE,
    headers: {
      Authorization:
        "Jwt eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYjJiIiwid2hvIjoiYXBwLWNvbmR1dG9yLWJoIiwiaWF0IjoxNTQ5Mjg1MDM2LCJleHAiOjQ3MDI4ODUwMzZ9.MKKbB_NmnOIYKAdidP7s8cIMSwZRRArX-m6EKkD8LDo",
      AcceptVersion: "1.0.0",
    },
  });
  instanceLoginCadastro.interceptors.response.use(
    (response) => response,
    (error) => {
      return Promise.reject(error);
    }
  );
  return instanceLoginCadastro;
};

const instanceLogin = (API) => {
  const instanceLogin = axios.create({
    baseURL: API,
    timeout: MINUTE,
    headers: {
      Authorization:
        "Jwt eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiZmlzY2FsIiwid2hvIjoiODM5NyIsImlhdCI6MTU4MDkwNDkxNCwiZXhwIjo0NzM0NTA0OTE0fQ.VpEQ7iGXk4SkU4JtI3f9Ka_AcdlAr1JphT-Bb0c4vAo",
      AcceptVersion: "1.0.0",
    },
  });
  instanceLogin.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error && error.response && error.response.data.message) {
        toast.error(error.response.data.message);
      }
      return Promise.reject(error);
    }
  );
  return instanceLogin;
};

const instance = (API) => {
  const instance = axios.create({
    baseURL: API,
    timeout: MINUTE,
    headers: {
      Authorization:
        "Jwt eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiaW50ZWdyYXRvciIsIndobyI6IjgyOSIsImlhdCI6MTU0NzIzODM1MiwiZXhwIjo0NzAwODM4MzUyfQ.9A9qEGkhM3XTHZaZfFtFrwtsMGotmN2qoVjblE8HFRY",
      AcceptVersion: "1.0.0",
    },
  });
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      return Promise.reject(error);
    }
  );
  return instance;
};

const instanceFiscal = (API) => {
  const instance = axios.create({
    baseURL: API,
    timeout: MINUTE,
    headers: {
      AcceptVersion: "1.0.0",
    },
  });
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      return Promise.reject(error);
    }
  );
  return instance;
};

const instanceIntegrator = (API) => {
  const instanceIntegrator = axios.create({
    baseURL: API,
    timeout: MINUTE,
    headers: {
      Authorization:
        "Jwt eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiaW50ZWdyYXRvciIsIndobyI6IjgyOSIsImlhdCI6MTU0NzIzODM1MiwiZXhwIjo0NzAwODM4MzUyfQ.9A9qEGkhM3XTHZaZfFtFrwtsMGotmN2qoVjblE8HFRY",
      AcceptVersion: "1.0.0",
    },
  });
  instanceIntegrator.interceptors.response.use(
    (response) => response,
    (error) => {
      return Promise.reject(error);
    }
  );
  return instanceIntegrator;
};

const instanceExternalVehicleModel = () => {
  const instanceExternalVehicleModel = axios.create({
    baseURL: "https://apicarros.com/v1",
    timeout: MINUTE,
  });
  instanceExternalVehicleModel.interceptors.response.use(
    (response) => response,
    (error) => {
      return Promise.reject(error);
    }
  );
  return instanceExternalVehicleModel;
};

const createFilters = (prefix, data) => {
  return data
    ? Object.keys(data)
        .map((key) => data[key] && `${prefix}[${key}]=${data[key]}`)
        .join("&")
    : "";
};

export const addAuthToken = (token) =>
  (instance().defaults.headers.common["Authorization"] = `Jwt ${token}`);

export const addAuthDomain = (domain) => {
  instance().defaults.headers.common["Domain"] = domain;
};

export const getModelVehicle = (params) =>
  instanceLoginCadastro(CADASTRA)
    .get(`/vehicle/${params}`)
    .then((response) => response)
    .catch((e) => {
      throw e;
    });

export const getModelVehicleV2 = (params) =>
  instanceExternalVehicleModel()
    .get(`/consulta/${params}/json`)
    .then((response) => response)
    .catch((e) => {
      throw e;
    });

export const getFiscalSupervisePhotos = (hash) =>
  instance(ARQUIVO)
    .get(`/file/?hash=${hash}`)
    .then((response) => response)
    .catch((e) => {
      throw e;
    });

export const cancelOrderPdv = (sellerId, nsu, date) =>
  instanceIntegrator(POS)
    .delete(`/transaction/${sellerId}/${nsu}/${date}`)
    .then((response) => response)
    .catch((e) => {
      throw e;
    });

export const processPayment = (paymentId) =>
  instance(TRANSACIONA)
    .put(`/payment/${paymentId}/authorize`, { canRefund: false })
    .then((response) => response)
    .catch((e) => {
      throw e;
    });

export const authorizeChargeback = (orderId) =>
  instance(TRANSACIONA)
    .delete(`/order/${orderId}/authorize`)
    .then((response) => response)
    .catch((e) => {
      throw e;
    });

export const refuseChargeback = (orderId) =>
  instance(TRANSACIONA)
    .delete(`/order/${orderId}/refuse`)
    .then((response) => response)
    .catch((e) => {
      throw e;
    });

export const getBalanceBHTransApp = () =>
  instance(BHTRANS)
    .get(`/util/balance`)
    .then((response) => response)
    .catch((e) => {
      throw e;
    });

export const getBalanceBHTransPdv = () =>
  instance(BHTRASPFDV)
    .get(`/util/balance`)
    .then((response) => response)
    .catch((e) => {
      throw e;
    });

export const signInFiscal = (params) =>
  instanceLogin(AUTENTICA)
    .post(`/fiscal/login`, params)
    .then((response) => {
      addAuthToken(response.data.token);
      return response;
    })
    .catch((e) => {
      throw e;
    });

export const getFiscalSupervises = (params) =>
  instanceFiscal(FISCALIZA)
    .get(
      `/history/supervises/0/99999?${createFilters("filters", params.filters)}`,
      { params: { ignoreMe: params.ignoreMe } }
    )
    .then((response) => response)
    .catch((e) => {
      throw e;
    });

export const getFiscalSupervisesDetails = (id) =>
  instanceFiscal(FISCALIZA)
    .get(`/supervise/${id}`)
    .then((response) => response)
    .catch((e) => {
      throw e;
    });

export const approveFiscalSupervise = (id) =>
  instanceFiscal(FISCALIZA)
    .put(`/supervise/${id}/approve`)
    .then((response) => response)
    .catch((e) => {
      throw e;
    });

export const refuseFiscalSupervise = (id) =>
  instanceFiscal(FISCALIZA)
    .put(`/supervise/${id}/refuse`)
    .then((response) => response)
    .catch((e) => {
      throw e;
    });
