import {
    getModelVehicle,
    getModelVehicleV2
} from '../repositories';

export const vehicle = {
    state: {},
    reducers: {
        clearStore() {
            return {};
        },
    },
    effects: dispatch => ({
        async getModelVehicleAsync(payload) {
            try {
                const response = await getModelVehicle(payload);
                const data = response.data != '' ? response.data : null
                if (response.data.model == '') {
                    const vehicle = await getModelVehicleV2(payload);
                    return vehicle ? { model: vehicle.data.modelo, color: vehicle.data.cor, manufactureYear: vehicle.data.ano, modelYear: vehicle.data.anoModelo } : null;
                } else {
                    return data;
                }
            } catch (e) {
                throw e;
            }

        },
        clearStores() {
            return new Promise(resolve => {
                dispatch.vehicle.clearStore();
                resolve();
            });
        },
    }),
};
