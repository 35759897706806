
import { ToastContainer, toast } from 'react-toastify';
import { addAuthDomain } from '../repositories'
import 'react-toastify/dist/ReactToastify.css';
import { auth } from '../utils/constants'

export const user = {
    state: {},
    reducers: {
        signIn(state, payload) {
            return {
                ...state,
                ...payload,
            };
        },
        clearStore() {
            return {};
        },
    },
    effects: dispatch => ({
        async signInAsync(payload) {
            try {
                const { username, password } = payload
                const findAuth = auth.find(key => key.username === username && key.password === password)
                const city = findAuth && findAuth.city || null
                if (!findAuth) {
                    toast.error('Usuário e/ou senha inválidos', {
                        position: toast.POSITION.TOP_RIGHT
                    })
                    Promise.error()
                }
                else {
                    if(city != "") addAuthDomain(city)
                    return dispatch.user.signIn({ username, city });
                }

            } catch (e) {
                throw e;
            }

        },
        async setCityAsync(city) {
           if(city != "") addAuthDomain(city)
           return dispatch.user.signIn({ city });
        },
        async refreshGetBalance() {

        },
        clearStores() {
            return new Promise(resolve => {
                dispatch.user.clearStore();
                resolve();
            });
        },
    }),
};
